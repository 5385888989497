import React from 'react';
import Helmet from 'react-helmet';

import './src/scss/global.scss';
import './index.css';

export const wrapRootElement = ({ element }) => (
	<>
		<Helmet>
			<title>Ksenya Samarskaya</title>
		</Helmet>
		{element}
	</>
);
